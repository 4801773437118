import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/elasticsearch-overview",
  "date": "20 Sept 2021",
  "title": "Elasticsearch on Skpr",
  "summary": "An overview of our Elasticsearch offering and how it can support your applications.",
  "author": "Nick Schuch",
  "tag": "Solution",
  "tagColor": "blue",
  "tags": [{
    "name": "solution"
  }, {
    "name": "elasticsearch"
  }, {
    "name": "service"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Skpr provides an Elasticsearch service for development teams that are looking to build feature-rich search functionality.`}</p>
    <p>{`In this blog post, we will cover two common architectures that can be configured.`}</p>
    <h3>{`Architectures`}</h3>
    <h4>{`Standard`}</h4>
    <p>{`This architecture is a simple approach that we see regularly with our clients.`}</p>
    <p>{`With this approach, the application is responsible for indexing, querying and rendering the output from Elasticsearch.`}</p>
    <p>{`Keeping both the cost and complexity down as it is only a single application performing all search-related operations.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/elasticsearch-overview/standard.jpg",
        "alt": "Diagram demonstrating a standard Elasticsearch architecture"
      }}></img></p>
    <h4>{`Direct Connection`}</h4>
    <p>{`Another popular pattern for development teams is to defer the querying and render (shown in the standard architecture) logic to javascript based frontends which can provide rich search experiences for site viewers. `}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/elasticsearch-overview/direct.jpg",
        "alt": "Diagram demonstrating an Elasticsearch architecture which could be used for a decoupled implementation"
      }}></img></p>
    <p>{`This architecture can also be used for:`}</p>
    <ul>
      <li parentName="ul">{`Federated Search by allowing multiple applications to index while using the proxy as a central search endpoint.`}</li>
      <li parentName="ul">{`Expose documents/content to external teams/solutions.`}</li>
      <li parentName="ul">{`Improving search performance by querying Elasticsearch directly.`}</li>
    </ul>
    <h3>{`Highly Available`}</h3>
    <p>{`Using `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/opensearch-service"
      }}>{`Amazon OpenSearch Service`}</a>{`, Skpr can provide a robust, highly available service to back your search functionality.`}</p>
    <h3>{`Security Features`}</h3>
    <p>{`Both architectures have the following security features.`}</p>
    <p><strong parentName="p">{`Role-Based Access Controls`}</strong></p>
    <p>{`Permissions are managed using the `}<a parentName="p" {...{
        "href": "https://opensearch.org"
      }}>{`Opensearch`}</a>{` RBAC system allows for fine-grained access controls.`}</p>
    <p>{`The Drupal application is given a set of credentials that can only read/write to specific indexes, while the Elasticsearch proxy is
only allowed to read from those indexes.`}</p>
    <p><strong parentName="p">{`Private Network`}</strong></p>
    <p>{`The managed Elasticsearch service is deployed to the internal network on the Skpr platform which means that only a Skpr application with
a set of credentials will be able to access it.`}</p>
    <p><strong parentName="p">{`Skpr Configuration`}</strong></p>
    <p>{`Credentials are provided to applications using the Skpr configuration system.`}</p>
    <p>{`For more information see our `}<a parentName="p" {...{
        "href": "/blog/architecting-skpr-configuration-system"
      }}>{`Skpr configuration blog post`}</a>{` which does a deep dive into how it works.`}</p>
    <h3>{`For More Information`}</h3>
    <p>{`Our Elasticsearch implementation is designed to be versatile and supports a wide range to use cases. `}</p>
    <p>{`Contact us today if you would like to discuss how our Elasticsearch service can work for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      